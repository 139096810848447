import React, { Component } from "react";
import axios from "axios";
import { apiURL } from "../../config";
import moment from "moment-timezone";
import ClassFilters from "./components/classFilters";
import ClassList from "./components/classList";
import "./index.css";
export default class ClassSchedule extends Component {
  state = {
    classLists: "",
    isLoading: false,
    filteredClassList: [],
    weekStartDate: moment().format("l"),
    weekEndDate: moment().add(6, "d").format("l"),
    classType: "all",
    selectedDate: moment().format("l"),
  };
  componentDidMount() {
    console.log("Class Schedule DateTime", moment().format());
    let currentDate = moment().format("l");
    let classSessions = this.props.classLists;
    console.log(classSessions);
    let filteredClassList = [];
    let filteredClassCount = 0;
    classSessions.forEach((element) => {
      //console.log("Inside Class Session loop ", element);
      if (moment(element.dtl_date).format("l") == currentDate) {
        filteredClassCount++;
        //console.log(filteredClassCount);
        //console.log(moment(element.dtl_date).format());
        //console.log(moment(element.dtl_date).isBefore(moment().format()));
        filteredClassList.push(element);
      }

      /* if (moment(element.dtl_date).format("l") == currentDate) {
        filteredClassCount++;
        //console.log(filteredClassCount);
        //console.log(moment(element.dtl_date).format());
        //console.log(moment(element.dtl_date).isBefore(moment().format()));
        filteredClassList.push(element);
      } */
    });
    //console.log("After foreach");
    const noClassesFound = filteredClassList.find(({ dtl_date }) =>
      moment(moment().format()).isBefore(dtl_date)
    );
    console.log("Class Found ", noClassesFound);
    if (!noClassesFound) {
      const nextDate = moment(this.state.selectedDate)
        .add("1", "d")
        .format("l");
      this.setState({
        selectedDate: nextDate,
      });
      this.updateFilteredClasses(nextDate, "all");
    } else {
      filteredClassList.sort((a, b) => moment(a.dtl_date) - moment(b.dtl_date));
      this.setState({ filteredClassList: filteredClassList });
    }
  }
  updateFilteredClasses = (selectedDate, classType) => {
    let currentDate = selectedDate;
    let classSessions = this.props.classLists;
    let filteredClassList = [];
    classSessions.forEach((element) => {
      if (
        moment(element.dtl_date).format("l") == selectedDate &&
        (element.s_title.toLowerCase().includes(classType) ||
          classType === "all")
      ) {
        //console.log("Date matched!");
        filteredClassList.push(element);
      }
    });
    filteredClassList.sort((a, b) => moment(a.dtl_date) - moment(b.dtl_date));
    //console.log("After foreach");
    this.setState({ filteredClassList: filteredClassList });
  };
  render() {
    return (
      <div class="container classSchedulingScreen">
        {this.state.filteredClassList && (
          <ClassFilters
            updateFilteredClasses={this.updateFilteredClasses}
            selectedDate={this.state.selectedDate}
            wpLocationData={this.props.wpLocationData}
          ></ClassFilters>
        )}

        {this.state.filteredClassList && (
          <ClassList
            filteredClassList={this.state.filteredClassList}
            locationData={this.props.locationData}
            updateClassSession={this.props.updateClassSession}
            updateClientType={this.props.updateClientType}
          ></ClassList>
        )}
      </div>
    );
  }
}
