import React, { Component, lazy, Suspense } from "react";
import logo from "./logo.svg";
import "./App.css";
import ErrorBoundary from "./screens/ErrorBoundary";
import axios from "axios";
import { apiURL, siteURL } from "./config";
import moment from "moment-timezone";
//const ClassSchedule = lazy(() => import("./screens/ClassSchedule"));
import ClassSchedule from "./screens/ClassSchedule";
//const Booking = lazy(() => import("./screens/Booking"));
import Booking from "./screens/Booking";
//const ThankYou = lazy(() => import("./screens/ThankYou"));
import ThankYou from "./screens/ThankYou";
import WeeklyClassSchedule from "./screens/WeeklyClassSchedule";
let localStorage = require("localStorage");
let k_business = localStorage.getItem("k_business");
//const isWeeklySchedule = localStorage.getItem("isWeeklySchedule");
const isWeeklySchedule = window.isWeeklySchedule || false;
let wpLocationID = localStorage.getItem("wpLocationID");
class App extends Component {
  initialize = () => {
    return {
      step: 1,
      businessID: k_business,
      isWeeklySchedule: isWeeklySchedule,
      locationData: "",
      clientData: "",
      locationAPIdone: false,
      isClientLoggedin: false,
      isLoading: true,
      classLists: "",
      classSession: "",
      wpLocationData: [],
      locationLoginTypes: [],
      clientType: "prospect",
    };
  };

  state = this.initialize();

  reset = () => {
    this.setState(this.initialize());
  };
  updateScreen = (step) => {
    this.setState({ step });
    //this.setState({ vij: step });
  };
  async loadLocationList() {
    const response = await fetch(
      siteURL + "/wp-json/wp/v2/location/" + wpLocationID
    );
    if (!response.ok) {
      return;
    }
    const locationData = await response.json();
    //console.log(locationData);
    this.setState({ wpLocationData: locationData.acf });
    /* if (locations.length) {
      let locationArray = [];
      locations.forEach((element) => {
        console.log(element);
        locationArray.push(element.acf);
      });
      if (locationArray.length === locations.length) {
        this.setState({ locationList: locationArray });
      }

    } */
  }
  componentDidMount() {
    console.log("Main App mounted!");
    let clientData = localStorage.getItem("askhClientData");
    //console.log(JSON.parse(clientData));
    if (clientData) {
      this.setState({ clientData: JSON.parse(clientData) });
    }
    this.loadLocationList();
    axios
      .get(apiURL, {
        params: {
          askhGetLocationsList: true,
          k_business: this.state.businessID,
        },
      })
      .then((response) => {
        //console.log("LocationAPI Response: ", response.data);
        let data = response.data;

        if (data.status === "ok") {
          this.setState({ locationAPIdone: true });

          this.setState({
            locationData: data.a_location[Object.keys(data.a_location)[0]],
          });

          //GetClass List
          axios
            .get(apiURL, {
              params: {
                askhGetClassTab: true,
                k_business: this.state.businessID,
                time: Date.now(),
              },
            })
            .then((response) => {
              //console.log(response);
              if (response.data.status === "ok") {
                this.setState({ isLoading: false });
                const a_session = response.data.a_session;
                /* const uniqueSessions = Array.from(
                  new Set(a_session.map((a) => a.k_class_period))
                ).map((id) => {
                  return a_session.find((a) => a.k_class_period === id);
                }); */
                const uniqueSessions = [
                  ...new Set(a_session.map((o) => JSON.stringify(o))),
                ].map((string) => JSON.parse(string));
                this.setState({ classLists: uniqueSessions });
              }
            })
            .catch((error) => {
              //console.log(error);
            });

          //GetClass List
          axios
            .get(apiURL, {
              params: {
                askhGetLoginTypes: true,
                k_business: this.state.businessID,
              },
            })
            .then((response) => {
              //console.log(response);
              if (response.data.status === "ok") {
                this.setState({
                  locationLoginTypes: response.data.a_login_type_list,
                });
              }
            })
            .catch((error) => {
              //console.log(error);
            });
        }
      })
      .catch((error) => console.log(error));
  }
  renderLoader = () => <div className="appLoader"></div>;
  updateClassSession = (a_session) => {
    this.setState({ isLoading: true });
    this.setState({ classSession: a_session });
    this.setState({ isLoading: false });
    this.updateScreen(2);
  };
  updateClientType = (clientType) => {
    this.setState({ clientType: clientType });
  };
  render() {
    return (
      <div className="classShedulingApp">
        {this.state.isLoading && <div className="appLoader"></div>}
        {this.state.step === 1 &&
          this.state.locationAPIdone &&
          this.state.classLists &&
          !this.state.isWeeklySchedule && (
            <ClassSchedule
              updateScreen={this.updateScreen}
              locationData={this.state.locationData}
              classLists={this.state.classLists}
              updateClassSession={this.updateClassSession}
              updateClientType={this.updateClientType}
              isWeeklySchedule={this.state.isWeeklySchedule}
              wpLocationData={this.state.wpLocationData}
            />
          )}
        {this.state.step === 1 &&
          this.state.locationAPIdone &&
          this.state.classLists &&
          this.state.isWeeklySchedule && (
            <WeeklyClassSchedule
              updateScreen={this.updateScreen}
              locationData={this.state.locationData}
              classLists={this.state.classLists}
              updateClassSession={this.updateClassSession}
              updateClientType={this.updateClientType}
              isWeeklySchedule={this.state.isWeeklySchedule}
            />
          )}
        {this.state.step === 2 && (
          <Booking
            updateScreen={this.updateScreen}
            locationData={this.state.locationData}
            classSession={this.state.classSession}
            wpLocationData={this.state.wpLocationData}
            loginTypes={this.state.locationLoginTypes}
            isWeeklySchedule={this.state.isWeeklySchedule}
            clientType={this.state.clientType}
            updateClientType={this.updateClientType}
          />
        )}
        {this.state.step === 3 && (
          <ThankYou
            updateScreen={this.updateScreen}
            locationData={this.state.locationData}
            classSession={this.state.classSession}
            clientData={this.state.clientData}
            isWeeklySchedule={this.state.isWeeklySchedule}
          />
        )}
      </div>
    );
  }
}

export default App;
