import React, { Component } from "react";
import moment from "moment-timezone";
let localStorage = require("localStorage");
export default class ThankYou extends Component {
  componentDidMount() {
    console.log("Thank You Screen Mounted!");
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "askhScheduled",
      bookingLocation: "microsite-homepage",
    });
  }

  render() {
    return (
      <div className="container thankYouScreen text-center">
        <div className="row">
          <div className="col-12">
            <p>You have successfully scheduled the following class:</p>
            <div className="classTitle">{this.props.classSession.s_title}</div>
            <div className="classTitle">
              <i class="fas fa-clock"></i>
              {moment(this.props.classSession.dtl_date).format("h:mm")}-
              {moment(this.props.classSession.dtl_date)
                .add(this.props.classSession.i_duration, "m")
                .format("h:mmA")}
            </div>
            <div className="classDate">
              <i class="fas fa-calendar-alt"></i>
              {moment(this.props.classSession.dtl_date).format(
                "dddd, MMMM D, YYYY"
              )}
            </div>
            <div className="classLocation">
              <i class="fas fa-map-marker-alt"></i>
              {this.props.locationData.s_title}
            </div>
            <div className="col-12">
              You should receive a confirmation email soon!
            </div>
            <div className="col-12">
              <button
                className="btn btn-dark book-again"
                onClick={() => this.props.updateScreen(1)}
              >
                Book Again
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
