import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import moment from "moment-timezone";
import { apiURL } from "../../../../config";

let localStorage = require("localStorage");
export default class MemberLogin extends Component {
  state = {
    error: "",
  };
  validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }
  errorMessage = (error) => {
    return <div className="form-errors">{error}</div>;
  };
  async searchClient(email) {
    const response = await axios.get(apiURL, {
      params: {
        askhSearchClient: true,
        text_email: email,
        k_business: this.props.locationData.k_business,
      },
    });
    const data = await response.data;
    console.log(data);
    if (data.is_use) {
      const clientData = {
        a_user: {
          firstName: data.a_user.text_firstname,
          lastName: data.a_user.text_lastname,
          email: email,
          phone: data.a_user.text_phone,
        },
        uid: data.uid_result,
      };

      this.props.updateClientData(clientData);

      //console.log(response.data);
      localStorage.setItem("askhClientData", JSON.stringify(clientData));
      //const a_business = response.data.a_business;
      let returnData = {
        can_book: false,
        user_found: true,
        uid: data.uid_result,
      };
      const response = await axios.get(apiURL, {
        params: {
          askhGetUserInfo: true,
          k_business: this.props.locationData.k_business,
          uid: data.uid_result,
        },
      });
      const userData = await response.data;
      const memberType = this.props.loginTypes.find(
        (element) => element.k_login_type === userData.k_login_type
      );
      console.log(memberType);
      if (memberType) {
        if (
          memberType.s_title === "Active Pass Holder" ||
          memberType.s_title === "Active Member"
        ) {
          returnData = {
            can_book: true,
            user_found: true,
            uid: data.uid_result,
          };
        } else {
          returnData = {
            can_book: false,
            user_found: true,
            uid: data.uid_result,
          };
        }
      }
      return returnData;
      //return data.is_use;
    } else {
      return { can_book: false, user_found: false };
    }
  }
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
  handleSubmit = (values) => {
    let phoneLink = "tel:" + this.props.wpLocationData.as_franchisePhone;
    this.setState({ error: "" });
    this.setState({ isLoading: true });

    this.searchClient(values.email).then((result) => {
      console.log(result);
      if (!result.user_found) {
        this.setState({
          error:
            "Thank you for your interest in KickHouse " +
            this.props.wpLocationData.as_franchiseName +
            ". You are currently unable to book this Members Only class. For a free trial, please schedule one of our KickStart classes or contact us at " +
            this.formatPhoneNumber(
              this.props.wpLocationData.as_franchisePhone
            ) +
            ".",
        });
      }
      if (result.can_book && result.user_found) {
        this.props.updateBookingStep(2);
      } else if (result.user_found && !result.can_book) {
        this.setState({
          error:
            "Thank you for your interest in KickHouse. You are currently unable to book this class because you are either registered with a different KickHouse location or you do not have membership credits on your account. To purchase a membership, please contact us at " +
            this.formatPhoneNumber(
              this.props.wpLocationData.as_franchisePhone
            ) +
            ".",
        });
        /* this.props.updateBookingStep(4);
        this.setState({ isLoading: false }); */
      }
      this.setState({ isLoading: false });
    });
  };
  render() {
    let errorMessage = [];
    {
      this.state.error &&
        errorMessage.push(
          <div class="alert alert-danger small" role="alert">
            {this.state.error}
          </div>
        );
    }
    let buttonLabel = "Next";
    if (this.state.isLoading) {
      buttonLabel = "Validating...";
    }
    return (
      <div>
        {errorMessage}
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => {
            // same shape as initial values
            //console.log(values);
            this.handleSubmit(values);
            //this.props.updateBookingStep(2);
          }}
        >
          {({ errors, touched, isValidating }) => (
            <Form>
              <div class="form-group">
                <Field
                  name="email"
                  validate={this.validateEmail}
                  className="form-control"
                  type="email"
                  placeholder="Email"
                />
                {errors.email &&
                  touched.email &&
                  this.errorMessage(errors.email)}
              </div>
              {/* <div class="form-group">
              <Field
                name="password"
                className="form-control"
                placeholder="Password"
              />
              {errors.password &&
                touched.password &&
                this.errorMessage(errors.password)}
            </div> */}
              <button
                type="submit"
                disabled={this.state.isLoading ? true : false}
                className="btn btn-outline-dark"
              >
                {buttonLabel}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}
