import React, { Component } from "react";
import axios from "axios";
import moment from "moment-timezone";

import DayPickerInput from "react-day-picker/DayPickerInput";

import "react-day-picker/lib/style.css";

class ChangeDateInput extends Component {
  constructor(props) {
    super(props);
    let { value, onChange, onFocus, onBlur } = props;
    console.log(props);
  }
  focus = () => {
    this.input.focus();
  };
  render() {
    let { value, onFocus, onBlur } = this.props;
    return (
      <button
        className="btn btn-link d-none d-sm-inline"
        ref={(el) => (this.input = el)}
        onBlur={onBlur}
        onClick={onFocus}
      >
        Change Date
      </button>
    );
  }
}

const ExampleCustomInput = ({ value, onClick }) => (
  <button className="example-custom-input" onClick={onClick}>
    Change Date
  </button>
);
export default class ClassFilters extends Component {
  state = {
    selectedDate: this.props.selectedDate,
    startDate: new Date(),
    selectedClassType: "all",
  };
  componentDidMount() {
    console.log("ClassFilters mounted!");
    const classList = this.props.selectedDate;
    //console.log("Class Filters", classList);
  }
  updatePrevDate() {
    let currentDate = this.state.selectedDate;
    let prevDate = moment(currentDate).subtract(1, "d");
    let weekEndDate = moment(this.props.weekStartDate).subtract(1, "d");
    let weekStartDate = moment(weekEndDate).subtract(6, "d");
    this.props.updateWeekStartDate(weekStartDate);
    this.props.updateWeekEndDate(weekEndDate);

    this.setState({
      selectedDate: moment(prevDate).format("l"),
    });

    this.props.updateFilteredClasses(
      moment(prevDate).format("l"),
      moment(weekStartDate).format("l"),
      moment(weekEndDate).format("l"),
      this.state.selectedClassType
    );
  }
  updateNextDate() {
    let currentDate = this.state.selectedDate;
    let nextDate = moment(currentDate).add(1, "d");
    let nextWeekStartDate = moment(this.props.weekEndDate).add(1, "d");
    let nextWeekEndDate = moment(nextWeekStartDate).add(6, "d");
    this.props.updateWeekStartDate(nextWeekStartDate);
    this.props.updateWeekEndDate(nextWeekEndDate);
    // this.props.updateWeekEndDate();
    this.setState({
      selectedDate: moment(nextDate).format("l"),
    });
    this.props.updateFilteredClasses(
      nextDate,
      moment(nextWeekStartDate).format("l"),
      moment(nextWeekEndDate).format("l"),
      this.state.selectedClassType
    );
  }
  classTypeFilter = (e) => {
    console.log("Class Filter", e.target.value);
    this.setState({ selectedClassType: e.target.value });
    this.props.updateFilteredClasses(
      this.state.selectedDate,
      moment(this.props.weekStartDate).format("l"),
      moment(this.props.weekEndDate).format("l"),
      e.target.value
    );
  };
  setStartDate = (date) => {
    this.setState({ startDate: moment(date).format("l") });
  };
  handleDayChange = (selectedDay, modifiers, dayPickerInput) => {
    const input = dayPickerInput.getInput();
    const selectedDate = moment(selectedDay).format("l");
    this.setState({ selectedDate: selectedDate });
    this.props.updateFilteredClasses(
      selectedDate,
      this.state.selectedClassType
    );
  };
  render() {
    let bannedDates = [];

    bannedDates.push({
      after: moment().add(2, "months").toDate(),
      before: moment().toDate(),
    });
    //console.log(bannedDates);
    return (
      <section className="classFilters">
        <div className="row">
          <div className="col-9 col-sm-7 pl-0">
            <button
              className="btn btn-outline-dark d-none d-sm-none d-md-inline"
              onClick={() => this.updatePrevDate()}
              disabled={
                this.state.selectedDate ===
                moment().tz("America/Los_Angeles").format("l")
                  ? true
                  : false
              }
            >
              <i class="fas fa-chevron-left"></i>prev Week
            </button>
            <span className="selectedDate">
              {moment(this.props.weekStartDate).format("MMMM D, YYYY")} -{" "}
              {moment(this.props.weekEndDate).format("MMMM D, YYYY")}
            </span>
            <button
              className="btn btn-outline-dark d-none d-sm-none d-md-inline"
              onClick={() => this.updateNextDate()}
            >
              next week<i class="fas fa-chevron-right"></i>
            </button>
            {/* <DayPickerInput
              value={this.state.selectedDate}
              component={ChangeDateInput}
              dayPickerProps={{
                disabledDays: {
                  after: moment().add(2, "months").toDate(),
                  before: moment().toDate(),
                },
              }}
              component={(props) => <ChangeDateInput {...props} />}
              onDayChange={this.handleDayChange}
            /> */}
          </div>
          <div className="col-3 col-sm-5 text-right p-0">
            <div className="mobilePrevNextBtn d-sm-none">
              <button
                className="btn btn-outline-dark"
                onClick={() => this.updatePrevDate()}
                disabled={
                  this.state.selectedDate ===
                  moment().tz("America/Los_Angeles").format("l")
                    ? true
                    : false
                }
              >
                <i class="fas fa-chevron-left"></i>prev Week
              </button>
              <button
                className="btn btn-outline-dark"
                onClick={() => this.updateNextDate()}
              >
                next Week<i class="fas fa-chevron-right"></i>
              </button>
            </div>

            <div class="input-group mb-3 d-none d-sm-none d-md-flex">
              <div class="input-group-prepend">
                <label class="input-group-text" for="classesGroupSelect">
                  Filter By Classes
                </label>
              </div>
              <select
                class="custom-select"
                id="classesGroupSelect"
                value={this.state.selectedClassType}
                onChange={(e) => this.classTypeFilter(e)}
              >
                <option value="all" selected>
                  All
                </option>
                <option value="kickstart">KickStart</option>
                <option value="classickick">ClassicKick</option>
                <option value="powerkick">PowerKick</option>
                <option value="kickrecovery">KickRecovery</option>
                <option value="familykick">FamilyKick</option>
                <option value="kickondemand">KickOnDemand</option>
                <option value="yourkicks">YourKicks</option>
              </select>
            </div>
          </div>

          <div className="col-12 d-sm-none mt-3 mb-4 p-0 mobile-class-type-filter">
            <div class="input-group">
              <div class="input-group-prepend">
                <label class="input-group-text" for="classesGroupSelect">
                  Filter By Classes
                </label>
              </div>
              <select
                class="custom-select"
                id="classesGroupSelect"
                value={this.state.selectedClassType}
                onChange={(e) => this.classTypeFilter(e)}
              >
                <option value="all" selected>
                  All
                </option>
                <option value="kickstart">KickStart</option>
                <option value="classickick">ClassicKick</option>
                <option value="powerkick">PowerKick</option>
                <option value="kickrecovery">KickRecovery</option>
                <option value="familykick">FamilyKick</option>
                <option value="kickondemand">KickOnDemand</option>
                <option value="yourkicks">YourKicks</option>
              </select>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
