import React, { Component } from "react";
import { apiURL } from "../../../../config";
import Axios from "axios";

export default class BookIntroClass extends Component {
  state = {
    isLoading: false,
    error: "",
  };
  async completeTransaction() {
    this.setState({ error: "" });
    this.setState({ isLoading: true });
    const a_data = {
      dt_date_gmt: this.props.classSession.dt_date,
      k_class_period: this.props.classSession.k_class_period,
      uid: this.props.clientData.uid,
    };
    const response = await Axios.get(apiURL, {
      params: {
        askhBookFreeClass: true,
        a_data: a_data,
      },
    });
    const data = await response.data;
    console.log(data);
    if (data.status === "ok") {
      this.setState({ isLoading: false });
      this.setState({ bookingSuccess: true });
      this.props.updateScreen(3);
    } else {
      this.setState({ bookingSuccess: false });
      this.setState({ isLoading: false });
      this.setState({ error: data.message });
    }
  }
  render() {
    let errorMessage = [];
    let buttonLabel = "Complete";
    if (this.state.isLoading) {
      buttonLabel = "Finalizing Booking...";
    }
    {
      this.state.error &&
        errorMessage.push(
          <div className="alert alert-danger small">{this.state.error}</div>
        );
    }
    return (
      <div className="row introClassBooking">
        <div className="col-12">
          {errorMessage}
          <div className="tet-center freeClassText">
            Welcome to KickHouse {this.props.wpLocationData.as_franchiseName}.
            Please claim your First Free Class by clicking the complete button
            below.
          </div>
        </div>
        <div className="col-12 bookingActionButtons text-right">
          <button
            className="btn btn-link cancel-btn"
            onClick={() => this.props.updateScreen(1)}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-dark back-step-btn"
            onClick={() => this.props.updateBookingStep(1)}
          >
            Back A Step
          </button>
          <button
            className="btn btn-outline-dark next-step-btn"
            onClick={() => this.completeTransaction()}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    );
  }
}
