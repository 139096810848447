import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import moment from "moment-timezone";
import { apiURL } from "../../../../config";
import MemberLogin from "./memberLogin";
let localStorage = require("localStorage");

export default class ProspectRegistration extends Component {
  state = {
    isLoading: false,
    uid: "",
  };
  validateEmail(value) {
    let error;
    if (!value) {
      error = "Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
      error = "Invalid email address";
    }
    return error;
  }

  validateFirstName(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  validateLastName(value) {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  }
  validatePhone(value) {
    let error;
    let cleaned = ("" + value).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (!value) {
      error = "Required";
    } else if (!match) {
      /* var intlCode = (match[1] ? '+1 ' : '')
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('') */
      error = "Please enter valid phone! Ideal format xxx-xxx-xxxx";
    }
    return error;
  }
  // Error Component
  errorMessage = (error) => {
    return <div className="form-errors">{error}</div>;
  };
  async searchClient(email) {
    const response = await axios.get(apiURL, {
      params: {
        askhSearchClient: true,
        text_email: email,
        k_business: this.props.locationData.k_business,
      },
    });
    const data = await response.data;
    console.log(data);
    if (data.is_use) {
      const clientData = {
        a_user: {
          firstName: data.a_user.text_firstname,
          lastName: data.a_user.text_lastname,
          email: email,
          phone: data.a_user.text_phone,
        },
        uid: data.uid_result,
      };

      /* const response = await axios.get(apiURL, {
        params: {
          askhGetUserInfo: true,
          k_business: this.props.locationData.k_business,
          uid: data.uid_result,
        },
      });
      const userData = await response.data;
      console.log("UserData: ", userData);
      if (userData.is_traveller) {
        this.registerClient(clientData, this.props.locationData.k_business);
        this.props.updateClientData(clientData);
      } else {
        this.props.updateClientData(clientData);
      }*/

      this.props.updateClientData(clientData);
      const response = await axios.get(apiURL, {
        params: {
          askhSearchClientInCloud: true,
          uid: data.uid_result,
        },
      });

      console.log(response.data);
      localStorage.setItem("askhClientData", JSON.stringify(clientData));
      const a_business = response.data.a_business;
      let returnData = {
        can_book: false,
        user_found: true,
        uid: data.uid_result,
      };
      if (response.data.status === "ok") {
        const result = a_business.find(
          ({ k_business }) => k_business === this.props.locationData.k_business
        );
        if (result) {
          console.log(result);
          returnData = {
            can_book: true,
            user_found: true,
            uid: data.uid_result,
          };
          //return returnData;
        }
      } else {
        returnData = { can_book: true, user_found: true, uid: data.uid_result };
        //return returnData;
      }
      return returnData;
      //return data.is_use;
    } else {
      return { can_book: false, user_found: false };
    }
  }
  async registerClient(values) {
    /* let phoneNumber = values.phone;
    let cleaned = ('' + phoneNumber).replace(/\D/g, '')
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
    phoneNumber = [match[2], '-', match[3], '-', match[4]].join('');
    console.log(phoneNumber); */
    const response = await axios.get(apiURL, {
      params: {
        askhPostLeadData: true,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        k_business: this.props.locationData.k_business,
      },
    });
    const data = await response.data;
    return data;
  }
  async askhIntroClassAvailability(uid) {
    const response = await axios.get(apiURL, {
      params: {
        askhGetPurchaseList: true,
        uid: uid,
        k_business: this.props.locationData.k_business,
      },
    });
    const data = await response.data;
    if (data.status === "ok") {
      console.log("Purchase List", data);
      if (data.a_purchase.length) {
        console.log("Purchase List length found", data.a_purchase.length);
        let a_purchase = data.a_purchase;
        console.log("Purchase List length found", a_purchase);
        let freeKickStartOption = a_purchase.find(
          (item) => item.s_title.toLowerCase() === "free kickstart class"
        );
        console.log(freeKickStartOption);
        if (
          typeof freeKickStartOption !== "undefined" &&
          Object.keys(freeKickStartOption).length !== 0 &&
          freeKickStartOption.constructor === Object
        ) {
          console.log(freeKickStartOption);
          this.askhGetPurchaseDetails(freeKickStartOption.k_purchase_item).then(
            (result) => {
              console.log(result);
              return result;
            }
          );
        } else {
          console.log("not found!");
          return false;
        }
      } else {
        console.log("else not found!");
        return false;
      }
    } else {
      console.log("else parent not found");
      return false;
    }
  }
  async askhGetPurchaseDetails(k_purchase_item) {
    const purchaseDetails = await axios.get(apiURL, {
      params: {
        askhGetPurchaseDetails: true,
        k_purchase_item: k_purchase_item,
      },
    });
    const purchaseDetailsData = await purchaseDetails.data;
    console.log(purchaseDetailsData);
    if (purchaseDetailsData.i_remain != 0) {
      return true;
    } else {
      return false;
    }
  }
  gravityFormSubmission = (clientData) => {
    var data = JSON.stringify({
      input_1: clientData.firstName,
      input_2: clientData.lastName,
      input_3: clientData.email,
      input_4: clientData.phone,
      input_5: clientData.location,
      input_7: window.location.href,
      field_values: "",
      source_page: 1,
      target_page: 0,
    });

    var config = {
      method: "post",
      url: "https://thekickhouse.com/wp-json/gf/v2/forms/9/submissions",
      headers: {
        Authorization:
          "Basic dmlqYXlAYW5nZWxzbWl0aC5uZXQ6eGF1MCBuM0lkIDRRbnYgMjF2aCAxWDJPIFVBUXM=",
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  handleSubmit = (values) => {
    this.setState({ error: "" });
    this.setState({ isLoading: true });
    /* this.registerClient(values).then((result) => {
      if (result.status === "ok") {
        console.log("Looks OK!");
        const clientData = {
          a_user: values,
          uid: result.uid,
        };
        console.log(result);
        this.props.updateClientData(clientData);
        this.setState({ uid: result.uid });

        this.props.updateBookingStep(2);
        this.setState({ isLoading: false });
      } else if (result.status === "login-member") {
        this.setState({ error: result.message });
        this.setState({ isLoading: false });
        this.searchClient(values.email).then((result) => {
          if (result.can_book && result.user_found) {
            this.props.updateBookingStep(2);
            this.setState({ isLoading: false });
          } else if (result.user_found && !result.can_book) {
            this.props.updateBookingStep(4);
            this.setState({ isLoading: false });
          }
        });
      } else {
        this.setState({ error: result.message });
        this.setState({ isLoading: false });
      }
    }); */
    let zapierData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      location: this.props.wpLocationData.as_franchiseName,
    };
    this.gravityFormSubmission(zapierData);
    this.searchClient(values.email).then((result) => {
      console.log(result);
      if (!result.user_found) {
        this.registerClient(values).then((result) => {
          if (result.status === "ok") {
            console.log("Looks OK!");
            const clientData = {
              a_user: values,
              uid: result.uid,
              isProspect: true,
            };
            const emailClientData = {
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phone: values.phone,
              uid: result.uid,
              location: this.props.wpLocationData.as_franchiseName,
            };
            axios
              .get(apiURL, {
                params: {
                  askhSendProspectEmail: true,
                  data: emailClientData,
                  pageLocation: window.location.href,
                  franchiseEmail: this.props.wpLocationData.as_franchiseEmail,
                  franchiseGmEmail:
                    this.props.wpLocationData.as_franchiseGMEmail,
                },
              })
              .then((response) => console.log("Email", response));
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "askhNewProspect",
              formLocation: "microsite-homepage",
              clientData: clientData,
            });
            console.log(result);
            this.props.updateClientData(clientData);
            this.setState({ uid: result.uid });
            if (
              this.props.classSession.s_title
                .toLowerCase()
                .includes("kickstart")
            ) {
              this.askhIntroClassAvailability(result.uid).then((r) => {
                console.log(r);
                if (r) {
                  this.props.updateBookingStep(5);
                } else {
                  this.props.updateBookingStep(2);
                }
                this.setState({ isLoading: false });
              });
            } else {
              this.props.updateBookingStep(2);
            }
            this.setState({ isLoading: false });
          } else {
            this.setState({ error: result.message });
            this.setState({ isLoading: false });
          }
        });
      }
      if (result.can_book && result.user_found) {
        this.askhIntroClassAvailability(result.uid).then((r) => {
          if (r) {
            this.props.updateBookingStep(5);
          } else {
            this.props.updateBookingStep(2);
          }
          this.setState({ isLoading: false });
        });
      } else if (result.user_found && !result.can_book) {
        this.props.updateBookingStep(4);
        this.setState({ isLoading: false });
      }
    });
  };
  render() {
    let errorMessage = [];
    {
      this.state.error &&
        errorMessage.push(
          <div class="alert alert-danger small" role="alert">
            {this.state.error}
          </div>
        );
    }
    let buttonLabel = "Next";
    if (this.state.isLoading) {
      buttonLabel = "Getting Next Steps...";
    }
    return (
      <div className="row registrationForm mt-3 mt-sm-0">
        <div className="col-12">
          <div className="sectionHeading h3">Fill in your details:</div>
          {errorMessage}
          {this.props.clientType === "member" && (
            <MemberLogin
              locationData={this.props.locationData}
              updateClientData={this.props.updateClientData}
              loginTypes={this.props.loginTypes}
              updateBookingStep={this.props.updateBookingStep}
              wpLocationData={this.props.wpLocationData}
            ></MemberLogin>
          )}
          {this.props.clientType !== "member" && (
            <Formik
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
              }}
              onSubmit={(values) => {
                // same shape as initial values
                //console.log(values);
                this.handleSubmit(values);
                //this.props.updateBookingStep(2);
              }}
            >
              {({ errors, touched, isValidating }) => (
                <Form>
                  <div class="form-group">
                    <Field
                      name="firstName"
                      validate={this.validateFirstName}
                      className="form-control"
                      placeholder="First Name"
                    />
                    {errors.firstName &&
                      touched.firstName &&
                      this.errorMessage(errors.firstName)}
                  </div>
                  <div class="form-group">
                    <Field
                      name="lastName"
                      validate={this.validateLastName}
                      className="form-control"
                      placeholder="Last Name"
                    />
                    {errors.lastName &&
                      touched.lastName &&
                      this.errorMessage(errors.lastName)}
                  </div>
                  <div class="form-group">
                    <Field
                      name="email"
                      validate={this.validateEmail}
                      className="form-control"
                      type="email"
                      placeholder="Email"
                    />
                    {errors.email &&
                      touched.email &&
                      this.errorMessage(errors.email)}
                  </div>
                  <div class="form-group">
                    <Field
                      name="phone"
                      validate={this.validatePhone}
                      className="form-control"
                      placeholder="Phone"
                    />
                    {errors.phone &&
                      touched.phone &&
                      this.errorMessage(errors.phone)}
                  </div>
                  <button
                    type="submit"
                    disabled={this.state.isLoading ? true : false}
                    className="btn btn-outline-dark"
                  >
                    {buttonLabel}
                  </button>
                </Form>
              )}
            </Formik>
          )}
        </div>

        <div className="col-12 bookingActionButtons text-right">
          <button
            className="btn btn-link cancel-btn"
            onClick={() => this.props.updateScreen(1)}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-dark back-step-btn"
            onClick={() => this.props.updateBookingStep(0)}
          >
            Back A Step
          </button>
        </div>
      </div>
    );
  }
}
