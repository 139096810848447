import React, { Component } from "react";

export default class BookingRestricted extends Component {
  formatPhoneNumber(phoneNumberString) {
    var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  }
  render() {
    let phoneLink = "tel:" + this.props.wpLocationData.as_franchisePhone;
    return (
      <div className="row bookingRestricted">
        <div className="col-12 text-center restriction-message">
          <p>
            Thank you for your interest in KickHouse{" "}
            {this.props.wpLocationData.as_franchiseName}.<br></br>You are
            currently registered at a different KickHouse location. <br></br>
            To book a class at our studio, please call us at{" "}
            <a href={phoneLink}>
              {this.formatPhoneNumber(
                this.props.wpLocationData.as_franchisePhone
              )}
            </a>
            .
          </p>
        </div>
        <div className="col-12 bookingActionButtons text-center">
          <button
            className="btn btn-outline-dark back-step-btn"
            onClick={() => this.props.updateBookingStep(1)}
          >
            Back A Step
          </button>
        </div>
      </div>
    );
  }
}
