import React, { Component } from "react";
import ClassListItem from "./classListItem";
import axios from "axios";
import { apiURL } from "../../../../config";
import equal from "fast-deep-equal";

import moment from "moment-timezone";

export default class ClassList extends Component {
  state = {
    isLoading: true,
    filteredClassList: [],
    noClasses: false,
    step: 1,
  };
  async askhGetStaffDetails(k_staff, k_business) {
    const response = await axios.get(apiURL, {
      params: {
        askhGetStaffDetails: true,
        k_staff: k_staff,
        k_business: k_business,
      },
    });
    const data = await response.data;
    console.log(data);
    if (data.status === "ok") {
      return data.a_staff;
    } else {
      return "";
    }
  }
  async askhGetClassInfo(k_class_period, dt_date) {
    const response = await axios.get(apiURL, {
      params: {
        askhGetClassInfo: true,
        k_class_period: k_class_period,
        dt_date: dt_date,
      },
    });
    const data = await response.data;
    console.log(data);
    if (data.status === "ok") {
      return data;
    } else {
      return false;
    }
  }
  componentDidMount() {
    console.log("classList Component mounted!");
    // console.log("Filtred Classes = ", this.props.filteredClassList);
    this.updateFilteredClasses();
  }
  async updateFilteredClasses() {
    let filteredClassList = this.props.filteredClassList;
    //console.log("LocationData at ClassList: ", this.props.locationData);
    let classItems = [];
    let classesCount = 1;
    this.setState({ filteredClassList: [] });
    this.setState({ isLoading: true });
    if (!filteredClassList.length) {
      this.setState({ isLoading: false });
      this.setState({ noClasses: true });
      this.setState({ filteredClassList: [] });
    } else {
      this.setState({ noClasses: false });

      classesCount = filteredClassList.length;
      console.log("classes lenght:", classesCount);

      console.time(".map()");
      await Promise.all(
        filteredClassList.map(async (element) => {
          const response = await axios.get(apiURL, {
            params: {
              askhGetClassInfo: true,
              k_class_period: element.k_class_period,
              dt_date: element.dt_date,
            },
          });
          const data = await response.data;
          if (data.status === "ok") {
            let classItem = {
              s_title: element.s_title,
              i_duration: element.i_duration,
              k_class: element.k_class,
              k_class_period: element.k_class_period,
              k_staff: element.a_staff[0],
              dt_date: element.dt_date,
              dt_time: element.dt_time,
              dtl_date: element.dtl_date,
              a_staff: data.a_staff[0],
              can_book: data.a_class.can_book,
              i_book: data.a_class.i_book,
              i_book_active: data.a_class.i_book_active,
              i_capacity: data.a_class.i_capacity,
              i_wait: data.a_class.i_wait,
              is_book: data.a_class.is_book,
              is_wait_list: data.a_class.is_wait_list,
              m_price: data.a_class.m_price,
            };
            classItems.push(classItem);
            console.log(classItems.length);
            if (classItems.length === filteredClassList.length) {
              classItems.sort(
                (a, b) => moment(a.dtl_date) - moment(b.dtl_date)
              );
              this.setState({ filteredClassList: classItems });
              this.setState({ isLoading: false });
            }
          } else {
          }
          console.log(data);
        })
      );
      console.timeEnd(".map()");
      /* filteredClassList.forEach((element) => {
        //console.log(element);
        //let classItem = {};
        console.log("forech iteration");

        this.askhGetClassInfo(element.k_class_period, element.dt_date).then(
          (result) => {
            console.log("staffListExecution");
            //console.log(result);
            if (result) {
              let classItem = {
                s_title: element.s_title,
                i_duration: element.i_duration,
                k_class: element.k_class,
                k_class_period: element.k_class_period,
                k_staff: element.a_staff[0],
                dt_date: element.dt_date,
                dt_time: element.dt_time,
                dtl_date: element.dtl_date,
                a_staff: result.a_staff[0],
                can_book: result.a_class.can_book,
                i_book: result.a_class.i_book,
                i_book_active: result.a_class.i_book_active,
                i_capacity: result.a_class.i_capacity,
                i_wait: result.a_class.i_wait,
                is_book: result.a_class.is_book,
                is_wait_list: result.a_class.is_wait_list,
                m_price: result.a_class.m_price,
              };

              classItems.push(classItem);
              console.log(classItems.length);
              if (classItems.length === filteredClassList.length) {
                classItems.sort(
                  (a, b) => moment(a.dtl_date) - moment(b.dtl_date)
                );
                this.setState({ filteredClassList: classItems });
                this.setState({ isLoading: false });
              }
            }
          }
        );
      }); */
      console.timeEnd(".forEach{}");
    }
  }
  componentDidUpdate(prevProps) {
    console.log("ClassList Updated!");
    //console.log(this.props.filteredClassList);

    if (!equal(this.props.filteredClassList, prevProps.filteredClassList)) {
      this.updateFilteredClasses();
    }
  }
  reserveClass = (classItem, type = "") => {
    //this.setState({ step: 2 });
    console.log("Reserve Class", classItem);
    this.setState({ reserveClass: classItem });
    let clientType = "prospect";
    if (!classItem.can_book) {
      clientType = "member";
      if (type === "DROPIN") {
        clientType = "prospect";
      }
    }
    this.props.updateClientType(clientType);
    this.props.updateClassSession(classItem);
  };
  render() {
    //console.log("Render at classlist !");
    let filteredClassList = this.state.filteredClassList;
    let classListItems = [];
    {
      filteredClassList.length &&
        filteredClassList.forEach((element) => {
          //console.log(element);
        });
    }
    let currentTime = moment();
    return (
      <section className="classLists">
        {this.state.isLoading && <div className="appLoader"></div>}

        {!this.state.isLoading &&
          this.state.filteredClassList.map((item) => {
            let classTime = item.dtl_date;
            let classTimingDiff = moment(classTime).diff(currentTime);
            //console.log("TIming Diff", classTimingDiff);
            let duration = moment.duration(classTimingDiff);
            let hoursDifference = duration.asHours();
            //console.log("Hours Diff", hoursDifference);
            let className = item.s_title.toLowerCase();
            return (
              <div className={"row classListItem class-" + className}>
                <div className="col-3 classTiming d-none d-sm-block">
                  {moment(item.dtl_date).format("h:mm")} {"-"}
                  {moment(item.dtl_date)
                    .add(item.i_duration, "m")
                    .format("h:mmA")}
                </div>
                <div className="col-12 classInformation col-sm-9">
                  <div className="row">
                    <div className="col-7 col-sm-6">
                      <div className="d-sm-none mobileClassTiming">
                        {moment(item.dtl_date).format("h:mm")}
                        {" - "}
                        {moment(item.dtl_date)
                          .add(item.i_duration, "m")
                          .format("h:mmA")}
                      </div>
                      <span className="classTitle">{item.s_title} </span>
                      <span className="classInstructor">
                        (with {item.a_staff.s_name} {item.a_staff.s_family})
                      </span>
                    </div>
                    <div className="col-5 col-sm-6 text-right">
                      <div className="row">
                        <div className="col-sm-7 order-2 order-sm-1">
                          {item.i_capacity - item.i_book <= 10 && (
                            <div className="classCapacity">
                              {item.i_capacity <= item.i_book
                                ? "No spots left"
                                : `Hurry! Only ${
                                    item.i_capacity - item.i_book
                                  } spots left`}
                              <br></br>
                            </div>
                          )}
                        </div>
                        <div className="col-sm-5 order-1 order-sm-2">
                          {/* {!item.can_book && classTimingDiff > 0 && (
                            <button
                              className="btn btn-dark btn-block"
                              onClick={() => this.reserveClass(item, "DROPIN")}
                              disabled={
                                classTimingDiff > 0
                                  ? item.i_capacity <= item.i_book
                                    ? false
                                    : false
                                  : true
                              }
                            >
                              Drop In
                            </button>
                          )} */}
                          <button
                            className="btn btn-dark btn-block"
                            onClick={() => this.reserveClass(item)}
                            disabled={
                              hoursDifference > 3
                                ? item.i_capacity <= item.i_book
                                  ? false
                                  : false
                                : true
                            }
                          >
                            {/* {item.can_book
                              ? item.i_capacity <= item.i_book
                                ? "Wait List"
                                : "Book Now"
                              : item.i_capacity <= item.i_book
                              ? "Members - Wait List"
                              : classTimingDiff > 0
                              ? "Members"
                              : "Book Now"} */}

                            {item.i_capacity <= item.i_book
                              ? "Join Waitlist"
                              : "Book Now"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        {this.state.noClasses && <div>No sessions matching your filters.</div>}
      </section>
    );
  }
}
