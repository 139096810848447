import React, { Component } from "react";
import moment from "moment-timezone";
export default class ReviewClassSession extends Component {
  render() {
    return (
      <div className="row classReviewSection">
        <div className="col-12">
          <div className="section-heading h3">Booking Summary</div>
          <div className="classTitle">{this.props.classSession.s_title}</div>
          <div className="classTiming">
            <i class="fas fa-clock"></i>
            {moment(this.props.classSession.dtl_date).format("h:mm")}-
            {moment(this.props.classSession.dtl_date)
              .add(this.props.classSession.i_duration, "m")
              .format("h:mmA")}
          </div>
          <div className="classDate">
            <i class="fas fa-calendar-alt"></i>
            {moment(this.props.classSession.dtl_date).format(
              "dddd, MMMM D, YYYY"
            )}
          </div>
          <div className="classInstructor">
            <i class="fas fa-user"></i>
            {this.props.classSession.a_staff.s_name}{" "}
            {this.props.classSession.a_staff.s_family}
          </div>
          <div className="classLocation">
            <i class="fas fa-map-marker-alt"></i>
            {this.props.locationData.s_title}
          </div>
          {this.props.selectedPurchaseOption && (
            <div class="classPurchaseOption">
              <div class="purchaseOptionHeading">Purchase Option</div>
              <p>{this.props.selectedPurchaseOption.s_title}</p>
              <p>${this.props.selectedPurchaseOption.f_price}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}
