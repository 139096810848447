import Axios from "axios";
import React, { Component } from "react";
import { apiURL } from "../../../../config";
import CreditCard from "./creditCard";

export default class Payment extends Component {
  state = {
    k_pay_owner: "",
    isFreeClass: false,
    isReadyForBooking: false,
  };
  async askhGetPayOwner(uid) {
    const response = await Axios.get(apiURL, {
      params: {
        askhGetPayOwner: true,
        uid: uid,
      },
    });
    const data = await response.data;
    console.log(data);
    return data.k_pay_owner;
  }
  updateCreditCard = (cc) => {
    this.setState({ k_pay_bank: cc });
    this.setState({ isReadyForBooking: true });
  };
  componentDidMount() {
    if (this.props.selectedPurchaseOption.f_price === "0.00") {
      this.setState({ isFreeClass: true });
      this.setState({ isReadyForBooking: true });
    }
    this.askhGetPayOwner(this.props.clientData.uid).then((result) => {
      this.setState({ k_pay_owner: result });
    });
  }
  async completeTransaction() {
    this.setState({ error: "" });
    this.setState({ isLoading: true });
    console.log(this.props.classSession);
    const a_data = {
      dt_date_gmt: this.props.classSession.dt_date,
      k_class_period: this.props.classSession.k_class_period,
      k_pay_bank: this.state.k_pay_bank,
      uid: this.props.clientData.uid,
      f_amount: this.props.selectedPurchaseOption.f_price,
      k_id: this.props.selectedPurchaseOption.k_id,
      id_purchase_item: this.props.selectedPurchaseOption.id_purchase_item,
    };

    if (this.props.selectedPurchaseOption.f_price === "0.00") {
      const response = await Axios.get(apiURL, {
        params: {
          askhBookFreeClass: true,
          a_data: a_data,
        },
      });
      const data = await response.data;
      if (data.status !== "ok") {
        this.setState({ error: data.message });
        this.setState({ isLoading: false });
      } else {
        this.setState({ success: true });
        this.props.updateScreen(3);
        this.setState({ isLoading: false });
      }
      console.log(response);
    } else {
      const response = await Axios.get(apiURL, {
        params: {
          askhBookPremiumClass: true,
          a_data: a_data,
        },
      });
      const data = await response.data;
      if (data.status !== "ok") {
        this.setState({ error: data.message });
        this.setState({ isLoading: false });
      } else {
        this.setState({ success: true });
        this.props.updateScreen(3);
        this.setState({ isLoading: false });
      }
      console.log(response);
    }
  }
  render() {
    let errorMessage = [];
    let buttonLabel = "Complete";
    if (this.state.isLoading) {
      buttonLabel = "Finalizing Booking...";
    }
    {
      this.state.error &&
        errorMessage.push(
          <div className="alert alert-danger">{this.state.error}</div>
        );
    }
    return (
      <div className="row paymentScreen">
        <div className="col-12">
          {errorMessage}
          {!this.state.isFreeClass && (
            <CreditCard
              clientData={this.props.clientData}
              locationData={this.props.locationData}
              k_pay_owner={this.state.k_pay_owner}
              updateCreditCard={this.updateCreditCard}
              selectedPurchaseOption={this.props.selectedPurchaseOption}
              classSession={this.props.classSession}
            />
          )}
          {this.state.isFreeClass && (
            <div className="tet-center freeClassText">
              Click complete button below to book class.
            </div>
          )}
        </div>
        <div className="col-12 bookingActionButtons text-right">
          <button
            className="btn btn-link cancel-btn"
            onClick={() => this.props.updateScreen(1)}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-dark back-step-btn"
            onClick={() => this.props.updateBookingStep(2)}
          >
            Back A Step
          </button>
          <button
            className="btn btn-outline-dark next-step-btn"
            onClick={() => this.props.updateScreen(3)}
            disabled={this.state.isReadyForBooking ? false : true}
            onClick={() => this.completeTransaction()}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    );
  }
}
