import React, { Component } from "react";
import moment from "moment-timezone";

export default class ClassListItem extends Component {
  componentDidMount() {
    console.log("classListItem Mounted!");
    console.log("Class Session: ", this.props.classSession.s_title);
  }
  render() {
    return (
      <div className="row classListItem">
        <div className="col-2 classTiming">
          {moment(this.props.classSession.dtl_date).format("h:mmA")} {"-"}
          {moment(this.props.classSession.dtl_date)
            .add(this.props.classSession.i_duration, "m")
            .format("h:mmA")}
        </div>
        <div className="col-10 classInformation">
          <div className="row">
            <div className="col-7">
              {this.props.classSession.s_title}
              (with {this.props.classSession.a_staff.s_name}{" "}
              {this.props.classSession.a_staff.s_family})
            </div>
            <div className="col-5 text-right">
              <div className="classCapacity">0/10 Capacity</div>
              <button onClick={this.props.reserveClass}>Book NOW</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
