import React, { Component } from "react";

export default class BookingType extends Component {
  updateBookingStep(type = "") {
    let clientType = "member";
    if (type === "DROPIN") {
      clientType = "prospect";
    }
    this.props.updateClientType(clientType);
    this.props.updateBookingStep(1);
  }
  render() {
    return (
      <div className="row">
        <h2>Purchase type</h2>
        <div className="col-12">
          <button
            className="btn btn-outline-dark btn-block dropin-btn"
            onClick={() => this.updateBookingStep("DROPIN")}
          >
            Drop In
          </button>
          <button
            className="btn btn-outline-dark btn-block"
            onClick={() => this.updateBookingStep()}
          >
            Member Only Access
          </button>
        </div>
        <div className="col-12 bookingActionButtons text-right">
          <button
            className="btn btn-link cancel-btn"
            onClick={() => this.props.updateScreen(1)}
          >
            Cancel
          </button>
          <button
            className="btn btn-outline-dark back-step-btn"
            onClick={() => this.props.updateScreen(1)}
          >
            Back A Step
          </button>
        </div>
      </div>
    );
  }
}
