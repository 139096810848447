import React, { Component } from "react";
import BookingType from "./components/bookingType";
import BookingRestricted from "./components/bookingRestricted";
import BookIntroClass from "./components/introClass";
import Payment from "./components/payment";
import ProspectRegistration from "./components/prospectRegistration";
import PurchaseOptions from "./components/purchaseOptions";
import ReviewClassSession from "./components/reviewBooking";
let localStorage = require("localStorage");
export default class Booking extends Component {
  state = {
    bookingStep: 0,
    clientData: [],
    selectedPurchaseOption: "",
  };
  componentDidMount() {
    const clientData = localStorage.getItem("askhClientData");
    if (clientData) {
      this.setState({
        clientData: JSON.parse(clientData),
      });
      //this.updateBookingStep(2);
    }
    console.log("Booking Screen mounted!");
  }
  updateBookingStep = (step) => {
    this.setState({ bookingStep: step });
  };
  updateClientData = (data) => {
    this.setState({ clientData: data });
  };
  updatePurchaseOption = (option) => {
    this.setState({ selectedPurchaseOption: option });
  };
  render() {
    return (
      <div className="container bookingScreen">
        <div className="row">
          <div className="col-sm-4">
            <ReviewClassSession
              classSession={this.props.classSession}
              selectedPurchaseOption={this.state.selectedPurchaseOption}
              locationData={this.props.locationData}
            ></ReviewClassSession>
          </div>
          <div className="col-sm-8 ">
            {this.state.bookingStep === 0 && (
              <BookingType
                updateScreen={this.props.updateScreen}
                updateClientType={this.props.updateClientType}
                updateBookingStep={this.updateBookingStep}
              />
            )}
            {this.state.bookingStep === 1 && (
              <ProspectRegistration
                updateBookingStep={this.updateBookingStep}
                locationData={this.props.locationData}
                updateClientData={this.updateClientData}
                updateScreen={this.props.updateScreen}
                loginTypes={this.props.loginTypes}
                clientType={this.props.clientType}
                classSession={this.props.classSession}
                wpLocationData={this.props.wpLocationData}
              ></ProspectRegistration>
            )}
            {this.state.bookingStep === 2 && (
              <PurchaseOptions
                updateBookingStep={this.updateBookingStep}
                updateScreen={this.props.updateScreen}
                locationData={this.props.locationData}
                classSession={this.props.classSession}
                clientData={this.state.clientData}
                updatePurchaseOption={this.updatePurchaseOption}
                selectedPurchaseOption={this.state.selectedPurchaseOption}
                wpLocationData={this.props.wpLocationData}
              ></PurchaseOptions>
            )}
            {this.state.bookingStep === 3 && (
              <Payment
                updateBookingStep={this.updateBookingStep}
                updateScreen={this.props.updateScreen}
                locationData={this.props.locationData}
                classSession={this.props.classSession}
                clientData={this.state.clientData}
                selectedPurchaseOption={this.state.selectedPurchaseOption}
              ></Payment>
            )}
            {this.state.bookingStep === 4 && (
              <BookingRestricted
                updateBookingStep={this.updateBookingStep}
                wpLocationData={this.props.wpLocationData}
              ></BookingRestricted>
            )}
            {this.state.bookingStep === 5 && (
              <BookIntroClass
                updateBookingStep={this.updateBookingStep}
                wpLocationData={this.props.wpLocationData}
                updateScreen={this.props.updateScreen}
                classSession={this.props.classSession}
                clientData={this.state.clientData}
              ></BookIntroClass>
            )}
          </div>
        </div>
      </div>
    );
  }
}
