import React, { Component } from "react";
import axios from "axios";
import { apiURL } from "../../../../config";
import BookCreditClass from "./bookCreditClass";

export default class PurchaseOptions extends Component {
  initialize = () => {
    return {
      purchaseOptions: [],
      isLoading: true,
      error: "",
      isCreditAvailable: true,
      bookingProcessStep: {},
    };
  };
  state = this.initialize();

  async componentDidMount() {
    this.initialize();
    const bookProcess = await axios.get(apiURL, {
      params: {
        askhGetBookingInformation: true,
        uid: this.props.clientData.uid,
        k_class_period: this.props.classSession.k_class_period,
        dt_date: this.props.classSession.dt_date,
      },
    });
    const bookProcessPath = await bookProcess.data.a_path;
    if (bookProcess.data.status === "ok") {
      this.setState({ bookingProcessStep: bookProcess.data });
      const creditsAvailable = bookProcessPath.find(
        (element) => element.id_book_process > 2
      );
      if (creditsAvailable) {
        this.setState({ isCreditAvailable: false });
        const response = await axios.get(apiURL, {
          params: {
            askhGetPurchaseOptions: true,
            k_business: this.props.locationData.k_business,
            dt: this.props.classSession.dt_date,
            k_class_period: this.props.classSession.k_class_period,
            uid: this.props.clientData.uid,
          },
        });
        const data = await response.data;
        if (data.status === "ok") {
          let pArray = data.a_purchase;
          this.setState({ purchaseOptions: data.a_purchase });
        } else {
          this.setState({ error: data.message });
        }
        this.setState({ isLoading: false });
      } else {
        this.setState({ isCreditAvailable: true });
      }
    } else {
      this.setState({ error: bookProcess.data.message });
    }
    this.setState({ isLoading: false });
  }
  updatePurchaseOption = (option) => {
    this.props.updatePurchaseOption(option);
    //this.props.updateBookingStep(3);
  };
  render() {
    let purchaseOptions = this.state.purchaseOptions;
    let errorMessage = [];
    if (this.state.error) {
      errorMessage.push(
        <div className="text-danger small">{this.state.error}</div>
      );
    }
    return (
      <div className="purchaseOptions row mt-3 mt-sm-0">
        {this.state.isLoading && <div className="appLoader"></div>}
        {this.state.isCreditAvailable && !this.state.isLoading && (
          <BookCreditClass
            classSession={this.props.classSession}
            clientData={this.props.clientData}
            wpLocationData={this.props.wpLocationData}
            updateBookingStep={this.props.updateBookingStep}
            updateScreen={this.props.updateScreen}
            bookingProcessStep={this.state.bookingProcessStep}
          />
        )}

        {!this.state.isCreditAvailable && !this.state.isLoading && (
          <div className="col-12">
            <div className="section-heading h3">
              Select from Available Purchase Options:
            </div>
            <p className="small">
              To book this class, you must select an option below and proceed to
              purchase.
            </p>
            {errorMessage}
            {this.state.isLoading && <div className="appLoader"></div>}
            {this.state.purchaseOptions.length !== 0 && (
              <div className="p-options-btn">
                {Object.keys(purchaseOptions).map((key) => {
                  if (
                    (purchaseOptions[key].s_class == "class-limit" &&
                      purchaseOptions[key].s_title
                        .toLowerCase()
                        .includes("drop in") &&
                      purchaseOptions[key].is_introductory === "0") ||
                    (purchaseOptions[key].is_introductory === "1" &&
                      !purchaseOptions[key].s_title
                        .toLowerCase()
                        .includes("free"))
                  ) {
                    console.log("DropINs", purchaseOptions[key]);
                    return (
                      <button
                        className={
                          this.props.selectedPurchaseOption.k_id ===
                          purchaseOptions[key].k_id
                            ? "btn btn-block btn-dark"
                            : "btn btn-block btn-outline-dark"
                        }
                        onClick={() =>
                          this.updatePurchaseOption(purchaseOptions[key])
                        }
                      >
                        <div className="d-none d-sm-block">
                          {" "}
                          {"$"} {purchaseOptions[key].f_price}
                          {" | "}
                          {purchaseOptions[key].s_title}
                          {" | "}
                          {purchaseOptions[key].s_duration}
                          {" | "}
                          {purchaseOptions[key].i_limit}{" "}
                          {purchaseOptions[key].sid_program_category}
                        </div>
                        <div className="d-flex d-sm-none mobile-purchase-option">
                          <div className="col-6">
                            {" "}
                            <span>
                              {"$"} {purchaseOptions[key].f_price}
                            </span>
                            <br></br>
                            <span> {purchaseOptions[key].s_title}</span>
                          </div>
                          <div className="col-6">
                            {" "}
                            {purchaseOptions[key].s_duration}
                            <br></br>
                            {purchaseOptions[key].i_limit} <br></br>
                            {purchaseOptions[key].sid_program_category}
                          </div>
                        </div>
                      </button>
                    );
                  }
                })}
              </div>
            )}
          </div>
        )}
        {!this.state.isCreditAvailable && (
          <div className="col-12 bookingActionButtons text-right">
            <button
              className="btn btn-link cancel-btn"
              onClick={() => this.props.updateScreen(1)}
            >
              Cancel
            </button>
            <button
              className="btn btn-outline-dark back-step-btn"
              onClick={() => this.props.updateBookingStep(1)}
            >
              Back A Step
            </button>
            <button
              className="btn btn-outline-dark next-btn"
              onClick={() => this.props.updateBookingStep(3)}
              disabled={this.props.selectedPurchaseOption ? false : true}
            >
              Next
            </button>
          </div>
        )}
      </div>
    );
  }
}
