import React, { Component } from "react";
import axios from "axios";
import { apiURL } from "../../config";
import moment from "moment-timezone";
import ClassFilters from "./components/classFilters";
import ClassList from "./components/classList";
import "./index.css";
export default class WeeklyClassSchedule extends Component {
  state = {
    classLists: "",
    isLoading: false,
    filteredClassList: [],
    weekStartDate: moment().format("l"),
    weekEndDate: moment().add(6, "d").format("l"),
    classType: "all",
    selectedDate: moment().format("l"),
    weekDays: [],
  };
  componentDidMount() {
    console.log("Class Schedule DateTime", moment().format());
    let currentDate = moment().format("l");
    let classSessions = this.props.classLists;
    //console.log(classSessions);
    let filteredClassList = [];
    let filteredClassCount = 0;
    this.setState({
      weekDays: this.enumerateDaysBetweenDates(
        this.state.weekStartDate,
        this.state.weekEndDate
      ),
    });
    classSessions.forEach((element) => {
      //console.log("Inside Class Session loop ", element);
      if (
        this.props.isWeeklySchedule &&
        moment(element.dtl_date).isBetween(
          this.state.weekStartDate,
          this.state.weekEndDate
        )
      ) {
        //console.log("inWeek", element);
        filteredClassCount++;
        //console.log(filteredClassCount);
        //console.log(moment(element.dtl_date).format());
        //console.log(moment(element.dtl_date).isBefore(moment().format()));
        filteredClassList.push(element);
      }

      /* if (moment(element.dtl_date).format("l") == currentDate) {
        filteredClassCount++;
        //console.log(filteredClassCount);
        //console.log(moment(element.dtl_date).format());
        //console.log(moment(element.dtl_date).isBefore(moment().format()));
        filteredClassList.push(element);
      } */
    });
    //console.log("After foreach");
    filteredClassList.sort((a, b) => moment(a.dtl_date) - moment(b.dtl_date));
    this.setState({ filteredClassList: filteredClassList });
    /* const noClassesFound = filteredClassList.find(({ dtl_date }) =>
      moment(moment().format()).isBefore(dtl_date)
    );
    console.log("Class Found ", noClassesFound);
    if (!noClassesFound) {
      const nextDate = moment(this.state.selectedDate)
        .add("1", "d")
        .format("l");
      this.setState({
        selectedDate: nextDate,
      });
      this.updateFilteredClasses(nextDate, "all");
    } else {
      filteredClassList.sort((a, b) => moment(a.dtl_date) - moment(b.dtl_date));
      this.setState({ filteredClassList: filteredClassList });
    } */
  }
  updateWeekStartDate = (date) => {
    //console.log("weekstart Date", moment(date).format("l"));
    this.setState({ weekStartDate: moment(date).format("l") });
  };
  updateWeekEndDate = (date) => {
    //console.log("week end date", moment(date).format("l"));
    this.setState({ weekEndDate: moment(date).format("l") });
  };
  enumerateDaysBetweenDates = (startDate, endDate) => {
    var dates = [];

    var currDate = moment(startDate).startOf("day");
    var lastDate = moment(endDate).startOf("day");
    dates.push(moment(currDate.clone().toDate()).format("l"));
    while (currDate.add(1, "days").diff(lastDate) <= 0) {
      //console.log(currDate.toDate());
      dates.push(moment(currDate.clone().toDate()).format("l"));
    }

    return dates;
  };
  updateFilteredClasses = (
    selectedDate,
    weekStartDate,
    weekEndDate,
    classType
  ) => {
    this.setState({
      weekDays: this.enumerateDaysBetweenDates(weekStartDate, weekEndDate),
    });
    let currentDate = selectedDate;
    console.log("selectedDate", selectedDate);
    console.log("weekstart", weekStartDate);
    console.log("weekend", weekEndDate);
    let classSessions = this.props.classLists;
    let filteredClassList = [];
    classSessions.forEach((element) => {
      //console.log(moment(element.dtl_date).format("l"));
      if (
        moment(element.dtl_date).isBetween(
          moment(weekStartDate),
          moment(weekEndDate)
        ) &&
        (element.s_title.toLowerCase().includes(classType) ||
          classType === "all")
      ) {
        //console.log("Date matched!");
        filteredClassList.push(element);
      }
    });
    filteredClassList.sort((a, b) => moment(a.dtl_date) - moment(b.dtl_date));
    //console.log("After foreach");
    this.setState({ filteredClassList: filteredClassList });
  };
  render() {
    return (
      <div class="container classSchedulingScreen">
        {this.state.filteredClassList && (
          <ClassFilters
            updateFilteredClasses={this.updateFilteredClasses}
            selectedDate={this.state.selectedDate}
            isWeeklySchedule={this.props.isWeeklySchedule}
            weekStartDate={this.state.weekStartDate}
            weekEndDate={this.state.weekEndDate}
            updateWeekStartDate={this.updateWeekStartDate}
            updateWeekEndDate={this.updateWeekEndDate}
          ></ClassFilters>
        )}

        {this.state.filteredClassList && (
          <ClassList
            filteredClassList={this.state.filteredClassList}
            locationData={this.props.locationData}
            updateClassSession={this.props.updateClassSession}
            weekEndDate={this.state.weekEndDate}
            weekStartDate={this.state.weekStartDate}
            updateClientType={this.props.updateClientType}
            weekDays={this.state.weekDays}
          ></ClassList>
        )}
      </div>
    );
  }
}
